/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {VideoIframe, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!VideoIframe) _missingMdxReference("VideoIframe", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "what-are-ear-wax-guards",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-ear-wax-guards",
    "aria-label": "what are ear wax guards permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What are ear wax guards?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Just like any other device you own, ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), " require some basic maintenance to keep them operating at their best. The hearing aid receiver sits inside your ear, so it is  exposed to ", React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/earwax/",
    className: "c-md-a"
  }, "ear wax"), " and other debris on a daily basis. The receiver can get clogged and cause performance issues if you’re not properly cleaning it and replacing the wax guards when needed."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Wax guards are tiny screens on the hearing aid that prevent ear wax, dust or debris from entering and clogging the hearing aid receiver."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "ear-wax-plays-an-important-role-for-your-ear",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ear-wax-plays-an-important-role-for-your-ear",
    "aria-label": "ear wax plays an important role for your ear permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ear wax plays an important role for your ear"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ear wax is usually out of sight, out of mind — a part of our bodies that we don’t often think about or discuss. But when you wear hearing aids, you have to monitor it on a regular basis. Ear wax is part of your ear’s natural defense that works to keep your ear healthy by keeping dirt and bacteria at bay. But while good for your ear, it can be bad if it’s clogging your hearing aids."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "wax-guards-protect-your-hearing-aids-and-your-ears",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#wax-guards-protect-your-hearing-aids-and-your-ears",
    "aria-label": "wax guards protect your hearing aids and your ears permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Wax guards protect your hearing aids, and your ears"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Wax guards act as screens that trap ear wax, dirt and dust, keeping your hearing aids operating at top level. When the performance of your hearing aids is affected by ear wax, your hearing clarity will be, too. You want to ensure you always have a wax guard on each hearing aid and that you change them regularly in order to protect your investment and keep hearing your best."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-often-to-replace-your-wax-guards",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-often-to-replace-your-wax-guards",
    "aria-label": "how often to replace your wax guards permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How often to replace your wax guards"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s important to check your hearing aids for wax every day. And while you should check your ", React.createElement(_components.a, {
    href: "/hearing-aids/user-guides/",
    className: "c-md-a"
  }, "hearing aid user guide"), " or ask your hearing specialist for the recommended schedule, most wax guards are usually changed once per month. If you notice the hearing aid isn’t performing at its best, make sure to check the wax filter to see if that could be the issue. You’ll also want to still lightly clean your devices when you remove them each night, according to your hearing aid instructions."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Wax guards are easy to change, as your hearing aid kit should come with easy-to-use tools to help you remove the old filter and add the new one. See your hearing aid user guide or ask your hearing specialist for help on how to change your wax guards."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-change-hearing-aid-wax-guards",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-change-hearing-aid-wax-guards",
    "aria-label": "how to change hearing aid wax guards permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to change hearing aid wax guards"), "\n", React.createElement(VideoIframe, {
    title: "Hear.com Tips and Tricks | How to Change Your Wax Guard",
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/CBMRgGwQ2vw?si=02l0cd-klHsNih3u"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "talk-with-a-hearing-care-specialist",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#talk-with-a-hearing-care-specialist",
    "aria-label": "talk with a hearing care specialist permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Talk with a hearing care specialist"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have questions about which hearing aid is right for you, one of our expert hearing care specialists can help. To get started, complete this quick questionnaire to help us understand your hearing needs and lifestyle. You may qualify for a 45-day no-risk hearing aid trial. We can find you the best hearing aid to help you get back to living your best life."), "\n", React.createElement(LandingPageCta, {
    copy: "Fill out our Questionnaire",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
